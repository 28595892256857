import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="spiral">
        <div className="container">
          <div className="square black">
            <div className="square">
              <div className="square black">
                <div className="square">
                  <div className="square black">
                    <div className="square">
                      <div className="square black">
                        <div className="square">
                          <div className="square black">
                            <div className="square">
                              <div className="square black">
                                <div className="square">
                                  <div className="square black">
                                    <div className="square">
                                      <div className="square black">
                                        <div className="square">
                                          <div className="square black">
                                            <div className="square">
                                              <div className="square black">
                                                <div className="square">
                                                  <div className="square black">
                                                    <div className="square">
                                                      <div className="square black">
                                                        <div className="square">
                                                          <div className="square black">
                                                            <div className="square">
                                                              <div className="square black">
                                                                <div className="square">
                                                                  <div className="square black">
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="msj">
        <img src={logo} alt='logo' />
        <p>
          Rancho Tenango
        </p>
      </div>
      <div className='patita'>
        <p>
          Este sitio está en construcción
        </p>
      </div>
    </div>

  );
}

export default App;
